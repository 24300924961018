/*
Dark background ; 282c34
*/
.label-small {
  font-size: var(--bs-body-font-size);
  text-align: justify;
}
.Appointment-appointment {
  background-color: #2EACB4 !important;
}

.Cell-dayOfMonth {
  color: #2999a0 !important;
}

.Cell-highlightedText {
  color: #2999a0 !important;

}


.navbar-toggler {
  background-color: #6c757d !important;
}


/*
le bon :
*/ /*
.MainLayout-inlineFlex {
  max-width: 70vw;
}

.MainLayout-background {
  max-width: 70vw;
}

.MainLayout-ordinaryHeaderBorder  {
  max-width: 70vw;
}*/


/*
.Toolbar-toolbar {
  color: #2999a0 !important;

}*/
/*.MuiToolbar-root {
  color: #2999a0 !important;

}*/
/*
.MuiPaper-root {
  max-width: 85vw;
}

.MainLayout-container {
  max-width: 85vw;
}
.MainLayout-stickyElement {
  max-width: 85vw;
}
*/

/*
.Container-container {
  max-width: 85vw;
}
.MainLayout-flexRow {
  max-width: 85vw;
}
.MainLayout-inlineFlex {
  max-width: 85vw;
}
.MainLayout-stickyElement {
  max-width: 85vw;
}

.MainLayout-background {
  max-width: 85vw;
}

.MainLayout-inlineFlex {
  max-width: 85vw;
}

.MainLayout-flexRow {
  width: 30px;
}
.MuiTableCell-root {
  width: 30px;
}
.MuiTableCell-sizeMedium {
  width: 30px;
}
.MuiTableCell-body {
  width: 30px;
}
.Cell-cell {
  width: 30px;
}
.MainLayout-ordinaryLeftPanelBorder {
  width: 30px;
}

.MainLayout-ordinaryHeaderBorder {
  width: 30px;
}*/


:root {
  min-width: 400px;
  --sd-mobile-width: 400px;
  /*width: 100%;*/
    --font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    --sd-rating-bad-color: var(--sjs-special-red, var(--red, #e60a3e));
    --sd-rating-normal-color: var(--sjs-special-yellow, var(--yellow, #ff9814));
    --sd-rating-good-color: var(--sjs-special-green, var(--green, #19b394));
    --sd-rating-bad-color-light: var(--sjs-special-red-light, var(--red-light, rgba(230, 10, 62, 0.1)));
    --sd-rating-normal-color-light: var(--sjs-special-yellow-light, var(--yellow-light, rgba(255, 152, 20, 0.1)));
    --sd-rating-good-color-light: var(--sjs-special-green-light, var(--green-light, rgba(25, 179, 148, 0.1)));
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13,110,253;
    --bs-secondary-rgb: 108,117,125;
    --bs-success-rgb: 25,135,84;
    --bs-info-rgb: 13,202,240;
    --bs-warning-rgb: 255,193,7;
    --bs-danger-rgb: 220,53,69;
    --bs-light-rgb: 248,249,250;
    --bs-dark-rgb: 33,37,41;
    --bs-primary-text-emphasis: #052c65;
    --bs-secondary-text-emphasis: #2b2f32;
    --bs-success-text-emphasis: #0a3622;
    --bs-info-text-emphasis: #055160;
    --bs-warning-text-emphasis: #664d03;
    --bs-danger-text-emphasis: #58151c;
    --bs-light-text-emphasis: #495057;
    --bs-dark-text-emphasis: #495057;
    --bs-primary-bg-subtle: #cfe2ff;
    --bs-secondary-bg-subtle: #e2e3e5;
    --bs-success-bg-subtle: #d1e7dd;
    --bs-info-bg-subtle: #cff4fc;
    --bs-warning-bg-subtle: #fff3cd;
    --bs-danger-bg-subtle: #f8d7da;
    --bs-light-bg-subtle: #fcfcfd;
    --bs-dark-bg-subtle: #ced4da;
    --bs-primary-border-subtle: #9ec5fe;
    --bs-secondary-border-subtle: #c4c8cb;
    --bs-success-border-subtle: #a3cfbb;
    --bs-info-border-subtle: #9eeaf9;
    --bs-warning-border-subtle: #ffe69c;
    --bs-danger-border-subtle: #f1aeb5;
    --bs-light-border-subtle: #e9ecef;
    --bs-dark-border-subtle: #adb5bd;
    --bs-white-rgb: 255,255,255;
    --bs-black-rgb: 0,0,0;
    --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem; 
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-color-rgb: 33,37,41;
    --bs-body-bg: #fff;
    --bs-body-bg-rgb: 255,255,255;
    --bs-emphasis-color: #000;
    --bs-emphasis-color-rgb: 0,0,0;
    --bs-secondary-color: rgba(33, 37, 41, 0.75);
    --bs-secondary-color-rgb: 33,37,41;
    --bs-secondary-bg: #e9ecef;
    --bs-secondary-bg-rgb: 233,236,239;
    --bs-tertiary-color: rgba(33, 37, 41, 0.5);
    --bs-tertiary-color-rgb: 33,37,41;
    --bs-tertiary-bg: #f8f9fa;
    --bs-tertiary-bg-rgb: 248,249,250;
    --bs-heading-color: inherit;
    --bs-link-color: #0d6efd;
    --bs-link-color-rgb: 13,110,253;
    --bs-link-decoration: underline;
    --bs-link-hover-color: #0a58ca;
    --bs-link-hover-color-rgb: 10,88,202;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-xxl: 2rem;
    --bs-border-radius-2xl: var(--bs-border-radius-xxl);
    --bs-border-radius-pill: 50rem;
    --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-focus-ring-width: 0.25rem;
    --bs-focus-ring-opacity: 0.25;
    --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
    --bs-form-valid-color: #198754;
    --bs-form-valid-border-color: #198754;
    --bs-form-invalid-color: #dc3545;
    --bs-form-invalid-border-color: #dc3545;
    --bs-breakpoint-xs: 0;
    --bs-breakpoint-sm: 576px;
    --bs-breakpoint-md: 768px;
    --bs-breakpoint-lg: 992px;
    --bs-breakpoint-xl: 1200px;
    --bs-breakpoint-xxl: 1400px;
    --sv-defaultV2-mark: true;
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    text-align: left;
  /* text-align: center; 
  font-size: calc(10px + 1vmin); */
    color: white;
    box-sizing: border-box;
    --sd-base-padding: calc(5 * var(--sjs-base-unit, var(--base-unit, 8px)));
    --sd-base-vertical-padding: calc(4 * var(--sjs-base-unit, var(--base-unit, 8px)));
    --sd-page-vertical-padding: calc(3 * var(--sjs-base-unit, var(--base-unit, 8px)));
    -webkit-font-smoothing: antialiased;
    
    --sd-timer-size: calc(18 * var(--sjs-base-unit, var(--base-unit, 8px)));
    font-family: var(--font-family, var(--font-family));
    background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
    position: relative;
    --sjs-general-backcolor: rgba(255, 255, 255, 1);
    --sjs-general-backcolor-dark: rgba(243, 243, 243, 1);
    --sjs-general-backcolor-dim: rgba(46, 172, 180, 1);
    --sjs-general-backcolor-dim-light: rgba(245, 245, 245, 1);
    --sjs-general-backcolor-dim-dark: rgba(234, 234, 234, 1);
    --sjs-general-forecolor-light: rgba(0, 0, 0, 0.43);
    --sjs-general-dim-forecolor: rgba(255, 255, 255, 1);
    --sjs-general-dim-forecolor-light: rgba(255, 255, 255, 0.8);
    --sjs-primary-backcolor: rgba(46, 172, 180, 1);
    --sjs-primary-backcolor-light: rgba(46, 172, 180, 0.1);
    --sjs-primary-backcolor-dark: rgba(23, 156, 165, 1);
    --sjs-base-unit: 8px;
    --sjs-corner-radius: 4px;
    --sjs-shadow-small: 0px 2px 0px 0px rgba(0, 0, 0, 0.2);
    --sjs-shadow-inner: inset 0px 2px 0px 0px rgba(0, 0, 0, 0.1);
    --sjs-border-light: rgba(0, 0, 0, 0.09);
    --sjs-border-default: rgba(0, 0, 0, 0.16);
    --sjs-general-forecolor: rgba(0, 0, 0, 0.91);
    --sjs-primary-forecolor: rgba(255, 255, 255, 1);
    --sjs-primary-forecolor-light: rgba(255, 255, 255, 0.25);
    --sjs-secondary-backcolor: rgba(255, 152, 20, 1);
    --sjs-secondary-backcolor-light: rgba(255, 152, 20, 0.1);
    --sjs-secondary-backcolor-semi-light: rgba(255, 152, 20, 0.25);
    --sjs-secondary-forecolor: rgba(255, 255, 255, 1);
    --sjs-secondary-forecolor-light: rgba(255, 255, 255, 0.25);
    --sjs-shadow-medium: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
    --sjs-shadow-large: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
    --sjs-border-inside: rgba(0, 0, 0, 0.16);
    --sjs-special-red: rgba(229, 10, 62, 1);
    --sjs-special-red-light: rgba(229, 10, 62, 0.1);
    --sjs-special-red-forecolor: rgba(255, 255, 255, 1);
    --sjs-special-green: rgba(25, 179, 148, 1);
    --sjs-special-green-light: rgba(25, 179, 148, 0.1);
    --sjs-special-green-forecolor: rgba(255, 255, 255, 1);
    --sjs-special-blue: rgba(67, 127, 217, 1);
    --sjs-special-blue-light: rgba(67, 127, 217, 0.1);
    --sjs-special-blue-forecolor: rgba(255, 255, 255, 1);
    --sjs-special-yellow: rgba(255, 152, 20, 1);
    --sjs-special-yellow-light: rgba(255, 152, 20, 0.1);
    --sjs-special-yellow-forecolor: rgba(255, 255, 255, 1);
    --sjs-font-questiontitle-family: Open Sans;
    --sjs-font-questiontitle-weight: 600;
    --sjs-font-questiontitle-color: rgba(0, 0, 0, 0.91);
    --sjs-font-questiontitle-size: 14px;
    --sjs-font-questiondescription-family: Open Sans;
    --sjs-font-questiondescription-weight: 400;
    --sjs-font-questiondescription-color: rgba(0, 0, 0, 0.43);
    --sjs-font-questiondescription-size: 12px;
    --sjs-font-editorfont-family: Open Sans;
    --sjs-font-editorfont-weight: 400;
    --sjs-font-editorfont-color: rgba(22, 22, 22, 1);
    --sjs-font-editorfont-size: 12px;
    
}


