.App {
  text-align: center;
  font-size: calc(6px + 2vmin);
  color: white;
}

.App-MainLogo {
  height: 15vmin;
  
}

.Float-image  {
  height: 15vmin;
  float: left;    
  margin: 15px 15px 15px 15px;
  border-radius: 50%;
  border:6px solid rgba(0, 0, 0, 0.25);
}

.Btn-red  {
  color: rgb(218, 6, 6) !important;
  border:6px solid rgba(198, 21, 21, 0.25);
  margin: 10px 10px 10px 10px;
}

.App-logoL2R {
  height: 20vmin;
  pointer-events: none;
}

.App-logoR2L {
  height: 20vmin;
  pointer-events: none;
}


.App-header {
  min-height: 21vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Calendar {
  min-height: 21vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  color: black;
  /*font-size: calc(8px + 2vmin);*/
}
/*
.fc-event {
    font-size: 0.5em;
}*/


.logos { 
  display: flex;
  justify-content: space-evenly;
  padding-top: 50px;
}

.App-link {
  color: #f1a20d;
}
